import React from 'react';
import b_flecha_CM from '../../assets/images/b-flecha-CM.webp';
import logo_CM from '../../assets/images/logo-cm.webp';

const CasitaMecatoTitle = ({}) => {
    return(<>
        <div className='b-sin-guirnalda w-100'>
        <div className="container pt-5">
        <div className="row">
            <div className="text-center mb-3 col-lg-5 col-md-4 col-sm-4"></div>
            <div className="text-center mb-3 col-lg-2 col-md-4 col-sm-4">
                <img src={logo_CM} width="100%" />
            </div>
            <div className="text-center mb-3 col-lg-5"></div>
            <h4 className="text-center pt-2 pb-2">COMBO NAVIDEÑO</h4> {/*pt-4 pb-3*/}
        </div>
        </div>
        <img className="flecha w-100 mt-4" src={b_flecha_CM} />
        </div>
    </>)
}

export default CasitaMecatoTitle;