import React from 'react';
import b_flecha from '../../assets/images/b-flecha.webp';

const ProductTitle = ({}) => {
    return(<>
        <div className='b-guirnalda2 w-100'>
        <div className="container pt-5">
        <div className="row">
            <h4 className="text-center pt-2 pb-2">CONOCE NUESTROS PRODUCTOS</h4> {/*pt-4 pb-3*/}
            <p className="text-center mb-5">Naturalmente ricos en fibra, vitaminas y minerales <br />que te nutren y ayudan a mejorar la digestión.</p>
        </div>
        </div>
        <img className="flecha w-100 mt-4" src={b_flecha} />
        </div>
    </>)
}

export default ProductTitle;