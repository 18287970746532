import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import ReactPixel from 'react-facebook-pixel';

import iconWhatsApp from '../../assets/images/icon-whatsapp.webp';


const ContactoLink = (props) => {
    const generateId = () => uuidv4();
    let num_cel = "3172360243";
    let nom_market = "Brios Whatsapp";
    let estilos = "btn btn-primary px-4 py-2 mb-3 "

    if(props.cel){
        num_cel = props.cel;
    }
    
    if(props.market){
        nom_market = props.market;
    }

    if(props.width === 100){
        estilos = estilos + "w-100 "
    }
    
    if(props.color){
        estilos = estilos + props.color
    }else{
        estilos = estilos + "whatsappbtn"
    }

    let linkWhatsapp = 'https://wa.me/' + '+57' + num_cel+'?text=Hola%20Brios%20Panaderia,%20quiero%20hacer%20un%20pedido'

    if(props.product){
        linkWhatsapp = linkWhatsapp + '%20de%20'+ props.product;
    }

    const redirectWhatsapp = (data) => {

        let current_timestamp = Math.floor(new Date() / 1000);
        const id_event = generateId();

        // Aqui va el codigo de PIXEL para cuando le da clic en Whatsapp
        ReactPixel.track('Contact', 
        {
            content_name: 'Contacto '+nom_market,
            content_category: 'Contact',
            external_id: props.userId,
            custom_data: 
            { 
                nombre_minimarket: nom_market, 
                telefono: num_cel
            },
            user_data: {
                client_ip_address: props.ip,
                client_user_agent: window.navigator.userAgent,
                fbc: props.fbc,
                fbp: props.fbp
            }
        }, 
        { 
            eventID: id_event 
        });


        //Aqui va el codigo de SERVIDOR para Pixel cuando le da clic en whatsapp
        const datas = {
            "data": [
                {
                    "event_name": "Contact",
                    "event_time": current_timestamp,
                    "event_id": id_event,
                    "action_source": "website",
                    "user_data": {
                        "client_ip_address": props.ip,
                        "client_user_agent": window.navigator.userAgent,
                        "fbc": props.fbc,
                        "fbp": props.fbp,
                        "external_id": props.userId,
                    },
                    "custom_data": {
                        "content_name": 'Contacto '+nom_market,
                        "content_category": 'Contact',
                        "nombre_minimarket": nom_market,
                        "telefono": num_cel
                    }
                }
            ]
        };

        fetch(
            `https://graph.facebook.com/v12.0/${props.pixel_id}/events?access_token=${props.access_token}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(datas)
            }
        )
        .then(response => response.json())
        .then(response => {
            console.log('Evento enviado con éxito:', response);
            console.log(datas)
            window.open(linkWhatsapp)
            //window.open('https://wa.link/9hhsgr')
        })
        .catch(error => {
            console.error('Error al enviar el evento:', error);
        });
    } 

    return (
        <a onClick={(e) => redirectWhatsapp()} target="_blank" className={estilos}>
        <img src={iconWhatsApp} alt="Pedido por WhatsApp" /> <span>Realizar pedido</span>
        </a>
    )

}

export default ContactoLink