import React from 'react';
import ContactoLink from './Contacto/contactoLink';
import logoWhite from '../assets/images/logo-brios.webp';

const Footer = ({ fbc, fbp, userId, ip, pixel_id, access_token }) => {
    return(
        <div className="col-sm-12 col-md-12 col-lg-6 container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg border-right pt-4">
                <p className="text-center"><img className="img-fluid" src={logoWhite} alt="Logo Pan Brios" /></p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg pt-4 mb-3">
                <p className='mb-3'>
                  <ContactoLink
                      ip={ip ? ip : ""} 
                      access_token={access_token} 
                      pixel_id={pixel_id} 
                      fbc={fbc} 
                      fbp={fbp} 
                      userId={userId} 
                  />
                </p>
                <p className='mb-3'>
                  <a href='mailto:contacto@brios.com.co' target='_blank' alt="Enviar correo al email de Brios">
                    <i className="fa-solid fa-envelope"></i>contacto@brios.com.co
                  </a>
                </p>
                <p className='mb-3'>
                  <a href='#distribuidores' alt="Ubicación de Brios">
                    <i className="fa-solid fa-location-dot"></i>Punto de Fábrica:<br/> 
                    <span className='text-small'>Carrera 23 #15-15, Cali - Colombia</span>
                  </a>
                </p>
                <p className="mb-3 mt-3">Conoce más en:</p>
                <p className='redes_sociales mb-3 mt-3'>
                  <a href="https://www.instagram.com/brios_panaderia/" className='instagram' target="_blank" alt="ruta a instagram de Brios">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a href="https://www.facebook.com/BriosPanaderia" className='facebook' target="_blank" alt="ruta a facebook de Brios">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </p>
              </div>
              <p className="text-center fs-6 mt-2 text-gray-600">
                © Brios 2024. Todos los derechos reservados.
              </p>
            </div>
          </div>
    )
}

export default Footer;